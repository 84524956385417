import { defineStore } from "pinia";
import { Configuration, SiteApi, Site, NodeEvent, NodeStat } from "@/generated/";
import { ref, inject } from "vue";
import { API_URL } from "@/config";
import { handleError } from "@/utils/errors";

export const useSiteStore = defineStore("sites", () => {
  const signalr = ref({} as any);
  const siteId = ref("");
  const apiConfig: Configuration = {
    basePath: API_URL,
    accessToken: window.localStorage.getItem("auth")?.toString(),
    isJsonMime: function (mime: string): boolean {
      return true;
    }
  };   
  const api = new SiteApi(apiConfig);
  const sites = ref([] as Site[]);
  const nodeEvents = ref([] as NodeStat[]);
  const currentEvents = ref([] as NodeStat[]);
  const allEvents = ref([] as NodeStat[]);
  const issues = ref([] as NodeStat[]);
  const loading = ref(false);
  const sig: any = inject("signalr");
  const gateways = ref([] as string[]);
  const nodes = ref([] as string[]);
  const events = ref([
      "Item/s Added",
      "Item/s Removed",
      "Outside Tolerance"
  ] as string[]);

function parseDate(dateText: string) : Date {
  const parser = new RegExp(/([0-9]{4})-([0-9]{2})-([0-9]{2})\s([0-9]{2}):([0-9]{2}):([0-9]{2})/)
  const date = parser.exec(dateText);
  return new Date(parseInt(date![1]), parseInt(date![2]), parseInt(date![3]), parseInt(date![4]), parseInt(date![5]), parseInt(date![6]));
}

  async function connectToHub(sideId:string) {
    siteId.value = sideId;
    sig.on("event-" + sideId, (event: NodeStat, id: string) => {
      if (id == siteId.value) {
          try {
            if (event.change!.indexOf("+-") > -1 || parseInt(event.change!) < -2000) event.change = "?";
          } catch (e) { console.log(e);}
          try {
            if (event.stock!.indexOf("+-") > -1 || parseInt(event.stock!) < -2000) event.stock = "?";
          } catch (e) { console.log(e)}
          
          nodeEvents.value = nodeEvents.value.filter((obj) => obj._id !== event._id);
          if (allEvents.value.find(x => x._id == event._id) == null) {
            if (events.value.length == 0 || events.value.indexOf(event.event!) > -1)
              if (nodes.value.length == 0 || nodes.value.indexOf(event.id!) > -1)
                if (gateways.value.length == 0 || gateways.value.indexOf(event.gateway!) > -1)
                  allEvents.value.unshift(event);
          }
          
          if (["Under Weight", "Over Weight", "Outside Tolerance", "Uncalibrated"].indexOf(event.event!) > -1) {
            issues.value = issues.value.filter(x => !(x.id == event.id));
            issues.value.unshift(event);
          } else {
            issues.value = issues.value.filter(x => !(x.id == event.id));
            if (event.config != undefined && event.config.minStock != undefined && event.stock != undefined && parseInt(event.stock) <= event.config.minStock) {
              nodeEvents.value = nodeEvents.value.filter(x => !(x.event == "Low Stock" && x.id == event.id));
              const lsEvent = Object.create(event);
              lsEvent.event = "Low Stock";
            }
          }
          
          nodeEvents.value.push(event);

          nodeEvents.value = nodeEvents.value.sort((a, b) => {
            return parseDate(b.time!).getTime() - parseDate(a.time!).getTime();
          });

          if (event.event != "Non-Event / Wobble") {
            currentEvents.value = currentEvents.value.filter(x => x.id != event.id);
            currentEvents.value.push(event);
            currentEvents.value = currentEvents.value.sort((a, b) => {
              return a.gateway! > b.gateway! ? 1 : -1;
            });
          }
      }
    });
  }

  const cleanData = () => {
    nodeEvents.value.forEach((event) => {
      try {
        if (event.change!.indexOf("+-") > -1 || parseInt(event.change!) < -2000) event.change = "?";
      } catch (e) { console.log(e);}
      try {
        if (event.stock!.indexOf("+-") > -1 || parseInt(event.stock!) < -2000) event.stock = "?";
      } catch (e) { console.log(e)}
    });
  }

  async function getSites(page:number, perPage:number, id:string) {
    loading.value = true;
    return await api.siteGetPage(id, page, perPage).then((response) => {
      if (response.data.success) {
        sites.value = response.data.content!;
        loading.value = false;
        return Promise.resolve(response.data);
      } else {
        handleError(response.data.message!);
        return Promise.reject(response.data);
      }
    });
  }

  async function getDashboard(id:string, xMinutes:number, gateways: string[]) {
    return await api.siteGetDashboard({xMinutes, gateways, siteID: id}).then((response) => {
      if (response.data.success) {
        nodeEvents.value = response.data.content!;
        cleanData();
        return Promise.resolve(response.data);
      } else {
        handleError(response.data.message!);
        return Promise.reject(response.data);
      }
    });
  }

  async function getAllEvents(id:string,  from: Date | null, to: Date | null) {
    const fromStr = from == null ? null : from!.toISOString();
    const toStr = to == null ? null : to!.toISOString();
    return await api.siteGetAllEvents({gateways: gateways.value, siteID: id, from: fromStr, to: toStr, nodes: nodes.value, events: events.value }).then((response) => {
      if (response.data.success) {
        allEvents.value = response.data.content!;
        return Promise.resolve(response.data);
      } else {
        handleError(response.data.message!);
        return Promise.reject(response.data);
      }
    });
  }

  async function getIssues(id:string, gateways: string[], xMins: number) {
    return await api.siteGetIssues({gateways, siteID: id, xMinutes: xMins}).then((response) => {
      if (response.data.success) {
        issues.value = response.data.content!;
        return Promise.resolve(response.data);
      } else {
        handleError(response.data.message!);
        return Promise.reject(response.data);
      }
    });
  }

  async function getCurrentStats(id:string, gateways: string[]) {
    return await api.siteGetCurrent({gateways, siteID: id}).then((response) => {
      if (response.data.success) {
        currentEvents.value = response.data.content!;
        currentEvents.value = currentEvents.value.sort((a, b) => {
          return a.gateway! > b.gateway! ? 1 : -1;
        });
        return Promise.resolve(response.data);
      } else {
        handleError(response.data.message!);
        return Promise.reject(response.data);
      }
    });
  }

  async function UpdateEventNote(node:NodeStat) {
    return await api.siteUpdateEventNote(node).then((response) => {
      if (response.data.success) {
        return Promise.resolve(true);
      } else {
        handleError(response.data.message!);
        return Promise.reject(false);
      }
    });
  }

  return {
    getSites,
    getDashboard,
    getCurrentStats,
    connectToHub,
    getAllEvents,
    getIssues,
    UpdateEventNote,
    sites,
    issues,
    loading,
    allEvents,
    nodeEvents,
    currentEvents,
    events,
    nodes,
    gateways
  }
})
